<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login-form">
        <el-form-item prop="mobile">
            <el-input placeholder="请输入手机号" v-model="ruleForm.mobile" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="passwd">
            <el-input style="background: #1040ad;" placeholder="请输入密码" v-model="ruleForm.passwd" show-password></el-input>
        </el-form-item>
        <el-form-item prop="captcha">
                <el-input v-model="ruleForm.captcha" placeholder="请输入图形验证码" class="reg-create-captcha-ipt">
                        <div slot="append" v-bind:style="{background:captchaBg}" class="captcha-img"
                            @click="refreshCaptcha">
                        </div>
                    </el-input>
        </el-form-item>
        <div style="margin-bottom:22px;">
            <el-checkbox v-model="ruleForm.remember">记住我</el-checkbox>
        </div>
        <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%;">登录</el-button>
        </el-form-item>
    </el-form>
</template>
<style>
    .reg-create-captcha-ipt .el-input-group__append {
        padding: 0 !important;
        overflow: hidden;
    }
</style>
<script>
    import { getCaptCha } from '@/service/common';
    import { loginByMobileAndPwd,createMobileAccountAgo } from '@/service/account';
    export default {
        props: {

        },
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入手机号'));
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
                    if (reg.test(value)) {
                        // createMobileAccountAgo({
                        //     mobile: this.ruleForm.mobile,
                        // }).then((rst) => {
                        //     if (rst.step<=1 && rst.has_password==0) {
                        //         this.$message.error('该手机号尚未设置密码，请切换至短信登录，继续完善注册信息');
                        //     } else {
                        //         callback();
                        //     }
                        // }).catch(err => {
                        //     console.log(err);
                        //     if (err.message == "该手机号尚未注册") {
                        //         return callback(new Error('该手机号尚未注册'));
                        //     }
                        //     callback();
                        // });
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            return {
                ruleForm: {
                    mobile: '',
                    passwd: '',
                    captcha:'',
                    captchaToken:'',
                    // 记住我
                    remember:false
                },
                rules: {
                    mobile: [{validator: checkPhone, trigger: 'blur'}],
                    passwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                    captcha: [
                        { required: true, message: '请输入图形验证码', trigger: 'blur' }
                    ]
                },
                captchaBg: null
            }
        },
        mounted() {
            this.refreshCaptcha();
        },
        methods: {
            refreshCaptcha() {
                getCaptCha().then(rsp => {
                    this.captchaBg = `url('data:image/svg+xml,${encodeURIComponent(rsp.svg)}') no-repeat center`;
                    this.ruleForm.captchaToken = rsp.token;
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.remember) {
                            this.ruleForm.remember=1;
                        }
                        loginByMobileAndPwd(this.ruleForm).then(()=>{
                            this.$emit("success");
                        }).catch(err=>{
                            this.$message.error(err.message);
                        });
                    } else {
                        this.refreshCaptcha();// 刷新二维码
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            regist() {
                this.$router.push('/register');
                this.$emit("close");
            },
            forget() {

            }
        }
    }

</script>
<style scoped>
    .captcha-img {
        height: 38px;
        background-size: 100% 100% !important;
        width: 130px;
        cursor: pointer;
    }
</style>