<template>
    <div class="home">
        <div class="admin-bg">
            <div :style="{backgroundImage: 'url(' + img + ')'}"></div>
        </div>
        <div class="fixed-login">
            <div class="login-header" style="padding-bottom:25px;">南康产业智联网 ADMIN</div>
            <div class="login-con" :style="{backgroundImage: 'url(' + imgLogin + ')'}">
                <div class="login-header" style="font-size:17px;">登录</div>
                <AccountLogin @success="onLoginSuccess" @close="onClose"></AccountLogin>
            </div>
        </div>
    </div>
</template>

<script>
    import AccountLogin from '@/components/login/account.vue'
    export default {
        components: {
            AccountLogin
        },
        data() {
            return {
                img:require('../assets/images/home/admin_bg.jpg'),
                imgLogin:require('../assets/images/home/admin-loginBg.png'),
                bannerHeight: '',
            }
        },
        mounted() {
        },
        methods: {
            onLoginSuccess(){
                this.$emit("success");
                this.$router.push('/center');
            },
            onClose(){
                this.$emit("close");
            }
        }
    }
</script>
<style scoped>
    .home {
        width: 100%;
        height: 100%;
    }
    .admin-bg {
        width: 100%;
        height: 100%;
    }
    .admin-bg>div {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position:center;
    }
    .backgroud {
        background: #F5F5F5;
        /* height: 480px; */
    }
    .fixed-login {
        position: fixed;
        top: 150px;
        right: 20%;
        z-index: 999;
        width: 350px;
    }
    .login-header {
        text-align: center;
        font-size: 22px;
        color: #06cae1;
        padding-bottom: 15px;
    }
    .login-con {
        padding: 35px;
        background-size:100% 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    
</style>